var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "chatbody" },
    [
      _c("div", { staticClass: "chat-container" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "chat-messages", attrs: { id: "chat-messages" } },
          [_c("p", [_vm._v(_vm._s(_vm.responseMessage))])]
        ),
        _c(
          "div",
          { staticClass: "chat-input" },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.requestMessage,
                  expression: "requestMessage",
                },
              ],
              attrs: { type: "text", placeholder: "Type a message..." },
              domProps: { value: _vm.requestMessage },
              on: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.clickSend.apply(null, arguments)
                },
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.requestMessage = $event.target.value
                },
              },
            }),
            _c("q-btn", {
              attrs: { flat: "", label: "전송" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.clickSend.apply(null, arguments)
                },
              },
            }),
          ],
          1
        ),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "chat-header" }, [
      _c("h2", [_vm._v("CHAT GPT")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }